.people-selector .resp {
  display: none;
}
.people {
  margin: 0 80px;
  padding-top: 100px;
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.people-left {
  width: 55%;
}
.people-heading {
  font-family: "Montserrat";
  font-size: 60px;
  font-weight: 700;
  margin: 0 0 10px;
  line-height: 1;
}

.people-selector {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 8px 16px;
  width: max-content;
  border-radius: 15px;
  background-color: #f3f3f3;
}

.people-selector p {
  font-family: "Source Sans Pro";
  margin: 0;
  color: #a0a0a0;
  padding: 4px 8px;
  border-radius: 10px;
  transition: color 0.1s ease-in;
  cursor: pointer;
}

.people-body-text {
  margin: 48px 0;
  font-family: "Source Sans Pro";
  margin: 0;
  color: #504f4f;
  font-size: 28px;
  padding: 4px 8px;
  width: 75%;
}

.people-right img {
  margin: 32px 0;
  width: 500px;
}

@media screen and (max-width: 1340px) {
  .people-heading {
    font-size: 50px;
  }

  .people-body-text {
    font-size: 26px;
  }

  .people-right img {
    margin: 32px 0;
    width: 450px;
  }
}

@media screen and (max-width: 1240px) {
  .people-heading {
    font-size: 45px;
  }

  .people-body-text {
    font-size: 22px;
  }

  .people-right img {
    margin: 32px 0;
    width: 400px;
  }
}

@media screen and (max-width: 425px) {
  .resp {
    display: block;
  }

  .desktop {
    display: none;
  }
  .people {
    margin: 0 24px;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .people-left {
    box-sizing: border-box;
    width: 100%;
  }

  .people-heading {
    font-size: 26px;
    text-align: center;
  }

  .people-selector {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin: 24px auto 0px;
  }

  .people-body-text {
    margin: 16px 0;
    font-size: 16px;
    color: #2b2b2b;
    box-sizing: border-box;
    width: 100%;
    height: 160px;
  }

  .people-right img {
    width: 280px;
    margin: auto;
    display: flex;
    justify-content: center;
  }
}
