.why-head {
  margin: 0 80px;
  padding-top: 150px;
}
.why-heading {
  font-family: "Montserrat";
  font-size: 60px;
  font-weight: 700;
  margin: 0 0 10px;
}
.why-subheading {
  font-family: "Source Sans Pro";
  font-size: 28px;
  font-weight: 500;
  margin: 5px;
  color: grey;
}

.why-head-row {
  margin: 80px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.why-head-row div {
  width: 380px;
}

.why-head-row div div img {
  height: 380px;
  width: 380px;
}
.why-head-row-title {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;

  margin: 8px 0;
}

.why-head-row-para {
  font-family: "Source Sans Pro";
  font-size: 16px;
  color: gray;

  margin: 0px;
}

@media screen and (max-width: 1450px) {
  .why-head-row div {
    width: 350px;
  }

  .why-head-row div div img {
    height: 350px;
    width: 350px;
  }
}

@media screen and (max-width: 1340px) {
  .why-heading {
    font-size: 50px;
  }

  .why-subheading {
    font-size: 26px;
  }

  .why-head-row div {
    width: 330px;
  }

  .why-head-row div div img {
    height: 330px;
    width: 330px;
  }
}

@media screen and (max-width: 1240px) {
  .why-heading {
    font-size: 45px;
  }

  .why-subheading {
    font-size: 22px;
  }

  .why-head-row div {
    width: 310px;
    margin-bottom: 24px;
  }

  .why-head-row div div img {
    height: 310px;
    width: 310px;
  }
}

.resp {
  display: none;
}

@media screen and (max-width: 425px) {
  .resp {
    display: block;
  }

  .why-head {
    margin: 32px 16px 24px;
    padding-top: 32px;
  }

  .why-heading {
    font-size: 26px;
    text-align: center;
  }

  .why-subheading {
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    margin: 0px;
  }

  .why-head-row {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  .why-head-row div {
    width: 280px;
  }

  .why-head-row div div img {
    height: 280px;
    width: 280px;
  }

  .why-head-row-title {
    font-size: 20px;
  }
}
