.footer {
  background-color: #f5f5f7;
  padding: 50px 160px 10px 160px;
}
a {
  color: black;
  text-decoration: none;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.footer-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.website-links {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.website-links h3 {
  color: #5d5d5f;
  font-family: "Montserrat";
}

.page-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;

  font-family: "Source Sans Pro";
}

.page-links p {
  margin: 10px;
}

.social-links {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  width: 100%;
}

.last-line {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-weight: 600;
  font-family: "Montserrat";
}

.resp {
  display: none;
}

@media screen and (max-width: 425px) {
  .desktop {
    display: none;
  }

  .resp {
    display: block;
  }

  .footer {
    background-color: #f5f5f7;
    padding: 24px;
  }

  .website-links h3 {
    font-size: 14px;
  }

  .page-links {
    margin: 10px 0px;
  }

  .page-links p {
    margin: 0px;
    font-size: 12px;
  }

  .last-line p {
    text-align: center;
    margin-top: 48px;
    font-size: 12px;
  }
  .footer-body {
    justify-content: space-between;
    padding: 0 12px;
  }
  .social-links {
    margin-right: 60px;
  }
}
