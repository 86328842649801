.collage {
  margin: 16px 0;
  background-color: black;
  height: 680px;
  overflow: hidden;
}
.collage-pictures-row {
  display: flex;
  flex-direction: row;
  max-height: 680px;
  position: relative;
  overflow: hidden;
}

.collage-pictures-col {
  display: flex;
  flex-direction: column;
}

.collage-pictures-row img {
  opacity: 0.3;
  z-index: 1;
}
.collage-pictures-row img:hover {
  opacity: 0.5;
  transition: 0.2s linear;
}

.collage-overlay {
  position: absolute;
  background-color: black;
  height: 100%;
  width: 100%;
  height: 680px;
  text-align: center;
}

.collage-overlay p {
  margin: 0;
}

.collage-overlay-text {
  z-index: 2;
  color: white;
  font-size: 60px;
  font-family: "Montserrat";
  margin: auto;
  font-weight: 500;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.collage-overlay div {
  margin: 250px 0px;
}

.resp {
  display: none;
}

@media screen and (max-width: 1400px) {
  .collage {
    height: 640px;
  }

  .collage-overlay {
    height: 640px;
  }
}

@media screen and (max-width: 1300px) {
  .collage {
    height: 600px;
  }

  .collage-overlay {
    height: 600px;
  }
}

.resp {
  display: none;
}

@media screen and (max-width: 425px) {
  .resp {
    display: block;
  }

  .desktop {
    display: none;
  }

  .collage {
    height: 400px;
  }
  .collage-overlay-text {
    font-size: 24px;
    margin: 0px;

    height: max-content;
  }

  .collage-overlay {
    height: 400px;
  }

  .collage-overlay .resp {
    margin: 17% 0px;
  }
}

@media screen and (max-width: 380px) {
  .collage {
    height: 400px;
  }
  .collage-overlay {
    height: 400px;
  }
}
